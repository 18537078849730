export function formatNumber(number: number): string {
    const [integerPart, decimalPart] = number.toString().split(".")
    const formattedIntegerPart = integerPart.replaceAll(
        /\B(?=(\d{3})+(?!\d))/g,
        " "
    )

    return decimalPart
        ? `${formattedIntegerPart}.${decimalPart}`
        : formattedIntegerPart
}
