import { AsyncSelect } from "components/UI"
import { EntityName, useStoreEntities } from "hooks/useStoreEntities"
import React from "react"
import { InputProps } from "../../types/interfaces"
import { Required } from "../UI/Required"
import TextError from "../UI/TextError"

const InputEntitySelect: React.FC<InputProps> = ({
    propertyContainer,
    textError,
}) => {
    const enitityName = propertyContainer.getName() as EntityName

    const { value, onChange, isDisabled, apiParams, apiUrl } =
        useStoreEntities(enitityName)

    const currentApiParams = Object.fromEntries(
        Object.entries(apiParams).map(([key, value]) => [
            key === "itemsGroup" ? "itemsGroups" : key,
            value,
        ])
    )

    return (
        <>
            <div className="row mb-3">
                <label className="col-xl-3 col-form-label">
                    {propertyContainer.get("required") ? <Required /> : null}
                    {propertyContainer.get("label")}
                </label>
                <div className="col-xl-9">
                    <AsyncSelect
                        value={value}
                        onChange={onChange}
                        name={enitityName}
                        aria-label="Default select"
                        id={enitityName}
                        isClearable
                        dataUrl={apiUrl}
                        isDisabled={isDisabled}
                        params={currentApiParams}
                    />
                    {textError && <TextError text={textError} />}
                </div>
            </div>
        </>
    )
}

export default InputEntitySelect
