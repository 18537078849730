type FilterValue = {
    value: string
    compareType: string
}

type IFilterValuesObject = Record<string, FilterValue>

export const getUrlFilters = (locationSearch: string) => {
    const filtersString = locationSearch?.match(/filter=[^&]+/)?.[0] ?? ""
    const filterValuesObject: IFilterValuesObject = filtersString
        .replace(/filter=/, "")
        .split(";")
        .filter(Boolean)
        .reduce((acc, filterItemString) => {
            const [key, compareType, value] = filterItemString.split("--")

            if (!key || !compareType || !value) return acc

            acc[key] = {
                value: decodeURIComponent(value),
                compareType,
            }
            return acc
        }, {} as IFilterValuesObject)

    const filter: Record<string, string> = {}
    const compareTypes: Record<string, string> = {}

    Object.entries(filterValuesObject).forEach(
        ([key, { value, compareType }]) => {
            filter[key] = value
            compareTypes[key] = compareType
        }
    )
    return {
        filter,
        compareTypes,
        filtersString,
        isEmpty:
            Object.keys(filter).length === 0 ||
            Object.keys(compareTypes).length === 0,
    }
}
