import dayjs, { Dayjs } from "dayjs"
import timezone from "dayjs/plugin/timezone"
import utc from "dayjs/plugin/utc"

dayjs.extend(utc)
dayjs.extend(timezone)

const dateTimeFormat = "YYYY-MM-DD HH:mm:ss"

export const getDateString = (dateString?: string | Dayjs, format?: string) => {
    if (
        (typeof dateString === "string" && !dateString?.trim()) ||
        dateString === undefined
    ) {
        return ""
    }

    if (!dayjs(dateString).isValid())
        return `Невозможно отформатировать дату(${dateString})`

    let base: Dayjs

    if (typeof dateString === "string") {
        const gmtMatch = dateString.match(/GMT([+-]\d{4})/)

        if (dateString.endsWith("+00:00")) {
            base = dayjs(dateString).utc()
        } else if (gmtMatch) {
            base = dayjs(dateString).tz("Europe/Minsk")
        } else {
            base = dayjs(dateString).tz("Europe/Minsk", true)
        }
    } else {
        base = dayjs(dateString).tz("Europe/Minsk", true)
    }

    return base.format(format ?? dateTimeFormat)
}
