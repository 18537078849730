import { SET_FIELDS } from "../store/fields/actions"
import api from "./api"

const getDispatcher =
    (value: string, fieldName: string, eventName: string, pageSlug?: string) =>
    (dispatch: any) => {
        const updateListValues = async (value: string) => {
            const { data } = await api.get<any>(
                `attribute/fields?type=${value}`
            )

            dispatch(SET_FIELDS(data.fields))
        }

        const uniqEvents: { [key: string]: () => void } = {
            [`attribute.datatype.OnChange`]: () => updateListValues(value),
        }

        const eventFunction = `${pageSlug}.${fieldName}.${eventName}`

        uniqEvents[eventFunction] && uniqEvents[eventFunction]()
    }

export const uniqueEvents: any = (
    event: any,
    eventName: string,
    pageSlug?: any
) => {
    return getDispatcher(
        event.target.value,
        event.target.id,
        eventName,
        pageSlug
    )
}

//TODO: !Not used this method
export const uniqueEventsAsync: any = (
    value: string,
    fieldName: string,
    eventName: string,
    pageSlug?: any
) => {
    return getDispatcher(value, fieldName, eventName, pageSlug)
}
