import { SearchOutlined } from "@ant-design/icons"
import { Card, Col, List, Modal, Row, Typography } from "antd"
import cls from "classnames"
import {
    GroupSelect,
    ModelSelect,
    ProducerSelect,
    SeriesSelect,
} from "components/Selects/newSelects"
import { DebounceInput } from "components/UI"
import { useEffect, useMemo, useState } from "react"
import MassAttributeModal from "./MassAttributeModal"
import { useMassAttrubuteReplace } from "./useMassAttibuteReplace"

export default function MassAttributeReplace() {
    const {
        activeGroup,
        setActiveGroup,
        activeProducer,
        setActiveProducer,
        activeSeries,
        setActiveSeries,
        activeModel,
        setActiveModel,
        attributeList,
        attributeListLoading,
        showAttributeContainer,
        activeAttribute,
        setActiveAttribute,
        activeValues,
        refetchAttributes,
    } = useMassAttrubuteReplace()

    const [activeValue, setActiveValue] = useState<[string, string]>()

    const [attributeFilter, setAttributeFilter] = useState("")
    const [valuesFilter, setValuesFilter] = useState("")
    const [showModal, setShowModal] = useState(false)

    const filtredAttributeList = useMemo(() => {
        const list = attributeList ?? []
        return attributeFilter
            ? list.filter((el) =>
                  el.attribute.name
                      .toLowerCase()
                      .match(attributeFilter.toLowerCase())
              )
            : list
    }, [attributeList, attributeFilter])

    const filterValuesList = useMemo(() => {
        const list = activeValues ?? []
        return valuesFilter
            ? list.filter(([_, name]) =>
                  name.toLowerCase().match(valuesFilter.toLowerCase())
              )
            : list
    }, [activeValues, valuesFilter])

    useEffect(() => {
        if (activeValue) {
            setShowModal(true)
        }
    }, [activeValue])

    return (
        <>
            <Row gutter={[10, 10]} className="max-width-normal">
                <Col span={24}>
                    <Card>
                        <Row className="fw " gutter={[15, 15]}>
                            <Col xs={24}>
                                <Typography.Title level={4}>
                                    Замена атрибутов
                                </Typography.Title>
                            </Col>

                            <Col xs={24}>
                                <GroupSelect
                                    activeGroup={activeGroup}
                                    setActiveGroup={setActiveGroup}
                                    required
                                />
                            </Col>

                            <Col xs={24}>
                                <ProducerSelect
                                    activeGroupId={activeGroup?.value}
                                    setActiveProducer={setActiveProducer}
                                    activeProducer={activeProducer}
                                    required
                                />
                            </Col>

                            <Col xs={24}>
                                <SeriesSelect
                                    activeGroupId={activeGroup?.value}
                                    activeProducerId={activeProducer?.value}
                                    activeSeries={activeSeries}
                                    setActiveSeries={setActiveSeries}
                                />
                            </Col>

                            <Col xs={24}>
                                <ModelSelect
                                    activeGroupId={activeGroup?.value}
                                    activeSeriesId={activeSeries?.value}
                                    activeModel={activeModel}
                                    setActiveModel={setActiveModel}
                                />
                            </Col>
                        </Row>
                    </Card>
                </Col>

                <Col span={24}>
                    <Row gutter={[10, 10]}>
                        {showAttributeContainer && (
                            <Col span={24} md={12}>
                                <Card>
                                    <List
                                        header={
                                            <Row>
                                                <Typography.Title level={4}>
                                                    Атрибуты
                                                </Typography.Title>
                                                <DebounceInput
                                                    initValue={""}
                                                    resolve={setAttributeFilter}
                                                    inputProps={{
                                                        prefix: (
                                                            <SearchOutlined
                                                                rev={undefined}
                                                            />
                                                        ),
                                                    }}
                                                />
                                            </Row>
                                        }
                                        className="list"
                                        loading={attributeListLoading}
                                        dataSource={filtredAttributeList}
                                        bordered
                                        renderItem={(item) => {
                                            return (
                                                <List.Item
                                                    className={cls({
                                                        "list-item": true,
                                                        "list-item__selected":
                                                            activeAttribute?.id ===
                                                            item.id,
                                                    })}
                                                    onClick={() =>
                                                        setActiveAttribute(item)
                                                    }
                                                >
                                                    {item.attribute.name}
                                                </List.Item>
                                            )
                                        }}
                                    />
                                </Card>
                            </Col>
                        )}
                        {activeAttribute && (
                            <Col span={24} md={12}>
                                <Card>
                                    <List
                                        header={
                                            <Row>
                                                <Typography.Title level={4}>
                                                    Значения
                                                </Typography.Title>
                                                <DebounceInput
                                                    initValue={""}
                                                    resolve={setValuesFilter}
                                                    inputProps={{
                                                        prefix: (
                                                            <SearchOutlined
                                                                rev={undefined}
                                                            />
                                                        ),
                                                    }}
                                                />
                                            </Row>
                                        }
                                        className="list"
                                        dataSource={filterValuesList}
                                        bordered
                                        renderItem={([id, name]) => {
                                            return (
                                                <List.Item
                                                    className="list-item"
                                                    onClick={() =>
                                                        setActiveValue([
                                                            id,
                                                            name,
                                                        ])
                                                    }
                                                >
                                                    {name}
                                                </List.Item>
                                            )
                                        }}
                                    />
                                </Card>
                            </Col>
                        )}
                    </Row>
                </Col>
            </Row>
            <Modal
                title={"Заменить значение"}
                open={!!showModal}
                onCancel={() => setShowModal(false)}
                okButtonProps={{ hidden: true }}
                cancelButtonProps={{ hidden: true }}
            >
                {activeValue &&
                    activeGroup &&
                    activeProducer &&
                    activeAttribute && (
                        <MassAttributeModal
                            hide={() => {
                                setShowModal(false)
                                refetchAttributes()
                            }}
                            activeValue={activeValue}
                            data={{
                                itemsGroup: activeGroup!.value,
                                producer: activeProducer!.value,
                                series: activeSeries?.value,
                                model: activeModel?.value,
                                attributeId: activeAttribute!.attribute.id,
                                oldValue: activeValue![0],
                                newValue: activeValue![1],
                            }}
                        />
                    )}
            </Modal>
        </>
    )
}
