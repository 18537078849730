import { Field } from "types/api"

export const getFieldValue = <ValueType extends any>(
    fields: Field[],
    key: string
): ValueType | undefined => {
    const field = fields.find((el) => el.name === key)
    return field?.value as ValueType | undefined
}

export const getFieldsValues = <T>(
    fields: Field[],
    ...keys: Array<keyof T>
): T => {
    return keys.reduce((result, key) => {
        const value = getFieldValue(fields, key as string)
        if (value !== undefined) {
            result[key] = value as T[keyof T]
        }
        return result
    }, {} as T)
}
