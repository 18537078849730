import { SelectEntity } from "components/Selects/types";
import { useCallback, useMemo } from "react";
import { useDispatch } from "react-redux";
import { useSelector } from "react-redux"
import { DELETE_FIELDS_VALUES, SET_CURRENT_VALUES } from "store/fields/actions";
import { RootState } from "store/types"
import { IdNameItem } from "types";

export type EntityName = 'model' | 'series' | 'itemsGroup' | 'producer';

type CurrentValues = any

const dependencies: Record<EntityName, EntityName[]> = {
    model: ['series', 'producer', 'itemsGroup'],
    series: ['producer', 'itemsGroup'],
    producer: ['itemsGroup'],
    itemsGroup: [],
}

const resetOnChange: Record<EntityName, EntityName[]> = {
    model: [],
    series: ['model'],
    producer: ['series', 'model'],
    itemsGroup: ['producer', 'series', 'model'],
}

const getApiUrl = (enitiyName: EntityName) => enitiyName === 'itemsGroup' ? 'items-group' : enitiyName;

// get params no care about what is it


// We keep entities selection values as { label, value }, in store and api it's been kept like { id, name }
// So we implement to functions which transform values from one type to another
const transformToIdName = (value: SelectEntity | null): IdNameItem | null =>
    value ? { id: value.value, name: value.label } : value

const transformToSelectEntity = (
    value: IdNameItem | null
): SelectEntity | null =>
    value ? { value: value.id, label: value.name } : value

const getParam = (value: any) => value?.id ?? value?.code ?? value

export const useStoreEntities = (entityName: EntityName, _group?: string) => {
    const fields = useSelector((state: RootState) => state.fields);
    const dispatch = useDispatch()
    const currentValuesGroup = _group ?? 'main'
    const currentValues = fields.currentValues[currentValuesGroup];
    const initFields = fields.initFields;

    const value = fields.currentValues?.[currentValuesGroup]?.[entityName];

    const onChange = useCallback((v: SelectEntity | null) => {
        dispatch(SET_CURRENT_VALUES({
            [entityName]: transformToIdName(v)
        }, currentValuesGroup));
        const resetFields = resetOnChange[entityName];
        if (resetFields.length) {

            dispatch(DELETE_FIELDS_VALUES({
                fields: resetFields,
                group: currentValuesGroup
            }))
        }
    }, [dispatch, SET_CURRENT_VALUES, DELETE_FIELDS_VALUES, entityName, transformToIdName])

    const isDisabled = useMemo(() => {
        if (!initFields) return false;
        const currentDependencies = dependencies[entityName];
        const dependenciesInFields = currentDependencies.filter((dependency) => initFields.some(el => el.name === dependency))
        return dependenciesInFields.some((dependency) => !currentValues?.[dependency])
    }, [entityName, initFields, currentValues])

    const apiParams = useMemo(() => {
        const currentDependencies = dependencies[entityName];
        return currentDependencies.reduce((acc, next) => {
            const key = entityName === 'model' && next === 'itemsGroup' ? 'itemsGroups' : next;
            acc[key as any] = getParam(currentValues[next])
            return acc
        }, {} as Record<any, any>)
    }, [entityName, currentValues])

    return {
        isDisabled,
        onChange,
        value: value ? transformToSelectEntity(value) : null,
        apiParams,
        apiUrl: getApiUrl(entityName)
    }
}